import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor} from '@angular/common/http';

import { defer, Observable } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { HriuConsole } from './console-utils';
import { MsalService } from '@azure/msal-angular';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SessionTimeoutComponent } from '../modals/session-timeout/session-timeout.component';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private accessTokens: { [key: string]: string } = {};
  private accessTokensExpiry: { [key: string]: Date } = {};
  private sessionTimeoutDialog?: MatDialogRef<SessionTimeoutComponent, any>;

  constructor(private msalService: MsalService, private matDialog: MatDialog) {
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.startsWith(environment.functionApp.basePath)) {
      return this.attachToken(req, next);
    }

    return next.handle(req);
  }

  public attachToken(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.accessTokensExpiry[environment.functionApp.adScope] && this.accessTokensExpiry[environment.functionApp.adScope] > new Date()) {
      this.accessTokens[environment.functionApp.adScope] = null as any;
      this.accessTokensExpiry[environment.functionApp.adScope] = null as any;
    }

    // Acquire a token for this request, and attach as proper auth header.
    if (!this.accessTokens[environment.functionApp.adScope]) {
      return this.acquireTokenSilent(false, environment.functionApp.adScope).pipe(
        catchError(async (error) => {
          HriuConsole.error('AuthService.acquireTokenSilent: ', error);
          await this.promptUserToLogin();
          return this.acquireTokenSilent(true, environment.functionApp.adScope);
        }),
        switchMap((x) => {
          const newRequest: HttpRequest<any> = req.clone({
            setHeaders: {
              Authorization: `Bearer ${x}`,
              "Accept": "*/*"
            },
          });

          return next.handle(newRequest);
        })
      );
    }

    return next.handle(req);
  }

  private promptUserToLogin(): Promise<any> {
    if (!this.sessionTimeoutDialog) {
      this.sessionTimeoutDialog = this.matDialog.open(SessionTimeoutComponent,
        {
          panelClass: 'session-timeout-panel',
          disableClose: true
        });
    }
    return this.sessionTimeoutDialog.afterClosed().toPromise().then(() => {
      this.sessionTimeoutDialog = null as any;
    });
  }

  private acquireTokenSilent(forceRefresh: boolean, ...scopes: string[]): Observable<string> {
    if (!Array.isArray(scopes) || scopes.length <= 0) {
      scopes = [];
    }

    return defer(() => this.msalService.acquireTokenSilent({ scopes, forceRefresh })).pipe(map(resp => resp.accessToken));
  }
}

