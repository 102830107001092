import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MsalService } from "@azure/msal-angular";
import { lastValueFrom } from "rxjs";
import { environment } from "src/environments/environment";
import { employeeDto } from "../models/employee.dto";
import { employeeNumberDto } from "../models/employeeNumber.dto";
import { ConfigService } from "./config.service";
import { GraphService } from "./graph.service";
import { MDHServiceUtils } from "../utilities/mdh-service-utils";


@Injectable({
    providedIn: 'root'
})


export class MDHService extends GraphService {
    constructor(authService: MsalService, private httpClient: HttpClient) {
        super(authService)
    }

    // TODO: use generated methods
    public async getEmployeeWorkEmailStartsWith(workEmailInput: string) {
        const graphTokens = await this.getTokens(ConfigService.protectedResources.apiGraph.scopes);

        let results: Array<employeeDto> = [];
        const httpOptions = {
            headers: new HttpHeaders(
                {
                    'Ocp-Apim-Subscription-Key': environment.mdh.apimSubsciptionKey,
                    'Authorization': 'Bearer ' + graphTokens.idToken
                })
        };

        const encodedUrl: string = encodeURI(`${environment.mdh.mdhOdataRootUrl}/Employees?$filter=startswith(WorkEmailAddress,'${workEmailInput}') and AssignmentStatus eq 'Active' and UEN ne null and UEN ne ''`);
        await lastValueFrom(this.httpClient.get(encodedUrl, httpOptions))
            .then(
                (resolve: any) => {
                    for (let element of resolve.value) {
                        const employee: employeeDto = {
                            uen: element.UEN,
                            firstName: element.LegalFirstName,
                            lastName: element.LegalLastName,
                            workEmail: element.WorkEmailAddress,
                            district: element.District,
                            employeeNumber: null,
                            fullName: element.LegalFirstName + ' ' + element.LegalLastName
                        };

                        results.push(employee);
                    }

                },
                (reject: any) => {
                    console.log(reject);
                }
            );

        return results
    }

    /**
     * 
     * @param firstNameInput: can be either prefered first name or legal first name 
     * 
     */
    public async getEmployeeFirstNameStartWith(firstNameInput: string) {
        const graphTokens = await this.getTokens(ConfigService.protectedResources.apiGraph.scopes);

        let results: Array<employeeDto> = [];
        const httpOptions = {
            headers: new HttpHeaders(
                {
                    'Ocp-Apim-Subscription-Key': environment.mdh.apimSubsciptionKey,
                    'Authorization': 'Bearer ' + graphTokens.idToken
                })
        };


        const encodedUrl: string = encodeURI(`${environment.mdh.mdhOdataRootUrl}/Employees?$filter=(startswith(LegalFirstName,'${firstNameInput}') or startswith(PreferredName, '${firstNameInput}')) and AssignmentStatus eq 'Active' and WorkEmailAddress ne null and WorkEmailAddress ne '' and UEN ne null and UEN ne '' `);
        await lastValueFrom(this.httpClient.get(encodedUrl, httpOptions))
            .then(
                (resolve: any) => {
                    for (let element of resolve.value) {
                        const employee: employeeDto = {
                            uen: element.UEN,
                            firstName: element.LegalFirstName,
                            lastName: element.LegalLastName,
                            workEmail: element.WorkEmailAddress,
                            district: element.District,
                            employeeNumber: null,
                            fullName: element.LegalFirstName + ' ' + element.LegalLastName
                        };

                        results.push(employee);
                    }

                },
                (reject: any) => {
                    console.log(reject);
                }
            );

        return results
    }

    public async getEmployeeLastNameStartWith(lastNameInput: string) {
        const graphTokens = await this.getTokens(ConfigService.protectedResources.apiGraph.scopes);

        let results: Array<employeeDto> = [];
        const httpOptions = {
            headers: new HttpHeaders(
                {
                    'Ocp-Apim-Subscription-Key': environment.mdh.apimSubsciptionKey,
                    'Authorization': 'Bearer ' + graphTokens.idToken
                })
        };

        const encodedUrl: string = encodeURI(`${environment.mdh.mdhOdataRootUrl}/Employees?$filter=startswith(LegalLastName,'${lastNameInput}') and AssignmentStatus eq 'Active' and WorkEmailAddress ne null and WorkEmailAddress ne '' and UEN ne null and UEN ne ''`);

        await lastValueFrom(this.httpClient.get(encodedUrl, httpOptions))
            .then(
                (resolve: any) => {
                    for (let element of resolve.value) {
                        const employee: employeeDto = {
                            uen: element.UEN,
                            firstName: element.LegalFirstName,
                            lastName: element.LegalLastName,
                            workEmail: element.WorkEmailAddress,
                            district: element.District,
                            employeeNumber: null,
                            fullName: element.LegalFirstName + ' ' + element.LegalLastName
                        };

                        results.push(employee);
                    }

                },
                (reject: any) => {
                    console.log(reject);
                }
            );

        return results
    }

    public async getEmployeeDetail(uen: string): Promise<employeeDto> {
        const graphTokens = await this.getTokens(ConfigService.protectedResources.apiGraph.scopes);

        const httpOptions = {
            headers: new HttpHeaders(
                {
                    'Ocp-Apim-Subscription-Key': environment.mdh.apimSubsciptionKey,
                    'Authorization': 'Bearer ' + graphTokens.idToken
                })
        };

        const encodedUrl: string = encodeURI(`${environment.mdh.mdhOdataRootUrl}/Employees?$select=UEN,LegalFirstName,LegalLastName,WorkEmailAddress,District,CountryOfEmployment&$filter=UEN eq '${uen}'&$expand=EmployeeNumbers($select=PayrollNumber,PayrollDistrict,LastHire)`);
        let searchedEmployee: employeeDto = await lastValueFrom(this.httpClient.get(encodedUrl, httpOptions))
            .then((resolve: any) => {                
                if (resolve.value[0].EmployeeNumbers.length !== 0) {
                    try{
                        resolve.value[0].EmployeeNumbers = this.parseEmployeeNumber(resolve.value[0].EmployeeNumbers, resolve.value[0].CountryOfEmployment);
                    }
                    catch (e) {
                        console.error(e);

                        return e;
                    }
                }
                const searchResult: employeeDto = {
                    uen: resolve.value[0].UEN,
                    firstName: resolve.value[0].LegalFirstName,
                    lastName: resolve.value[0].LegalLastName,
                    workEmail: resolve.value[0].WorkEmailAddress,
                    district: resolve.value[0].District,
                    employeeNumber: resolve.value[0].EmployeeNumbers,
                    fullName: resolve.value[0].LegalFirstName + ' ' + resolve.value[0].LegalLastName
                }
                return searchResult;
            },
                (reject) => {
                    console.warn(reject);
                    return reject;
                })


        return searchedEmployee;
    }

    /**
     * use district number to determine pay library
     * the output will be pay library + E1 employee numebr padded to 5 digits e.g. D0198765
     * always returns an array of 1 element with parsed employee number stored in payrollNumber
     * @param employeeNumbers 
     * @returns 
     */
    private parseEmployeeNumber(employeeNumbers: Array<employeeNumberDto>, country: string): Array<employeeNumberDto> {
        if (employeeNumbers.length > 0) {
            let indexToReturn: number = 0;

            if (employeeNumbers.length > 1) {
                let mostRecentHireDate = new Date('1900-01-01').getTime();
                for (let i = 0; i < employeeNumbers.length; i++) {
                    let currentLastHireDate = new Date(employeeNumbers[i].LastHire).getTime();
                    if (currentLastHireDate > mostRecentHireDate) {
                        indexToReturn = i;
                    }
                }
            }

            // 50 - 90 D56
            // over 90 Australia ?
            if (country) {
                let payrollLibrary = MDHServiceUtils.convertDistrictToPayLibrary(employeeNumbers[indexToReturn].PayrollDistrict, country);
                // pad the payroll number to 5 digits
                employeeNumbers[indexToReturn].PayrollNumber = payrollLibrary + employeeNumbers[indexToReturn].PayrollNumber.padStart(5, '0');
                let employeeNumberToReturn: Array<employeeNumberDto> = [employeeNumbers[indexToReturn]];
                
                return employeeNumberToReturn;
            }
            else{
                throw("there is no country of employment");
            }
        }
        else {
            return employeeNumbers;
        }
    }


    public async updatePhotoFields() {
        throw ("Not Yet Implemented");
    }
}