import { environment } from "src/environments/environment";
export class HriuConsole {
    constructor() {
    }
    static getConsolePrefix(): string {
        return '[Hriu\u2122] ' + new Date().toLocaleString([], { hour: '2-digit', minute: '2-digit', second: '2-digit' }) + ': ';
    }
    static debug(message?: any, ...optionalParams: any[]) {
        if (environment.enableConsole) {
            console.debug(HriuConsole.getConsolePrefix() + message, ...optionalParams);
        }
    }
    static error(message?: any, ...optionalParams: any[]) {
        if (environment.enableConsole) {
            console.error(HriuConsole.getConsolePrefix() + message, ...optionalParams);
        }
    }
    static info(message?: any, ...optionalParams: any[]) {
        if (environment.enableConsole) {
            console.info(HriuConsole.getConsolePrefix() + message, ...optionalParams);
        }
    }
    static log(message?: any, ...optionalParams: any[]) {
        if (environment.enableConsole) {
            console.log(HriuConsole.getConsolePrefix() + message, ...optionalParams);
        }
    }
    static trace(message?: any, ...optionalParams: any[]) {
        if (environment.enableConsole) {
            console.trace(HriuConsole.getConsolePrefix() + message, ...optionalParams);
        }
    }
    static warn(message?: any, ...optionalParams: any[]) {
        if (environment.enableConsole) {
            console.warn(HriuConsole.getConsolePrefix() + message, ...optionalParams);
        }
    }
    static profilerTrackStart(key: string) {
        console.time(key);
    }
    static profilerTrackEnd(key: string) {
        console.timeEnd(key);
    }
}