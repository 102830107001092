import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
    selector: 'checkbox-cell-renderer',
    templateUrl: './action-button-cell-renderer.html',
    styleUrls: ['./action-button-cell-renderer.scss']
})


export class actionButtonCellRender implements ICellRendererAngularComp {
    private params!: ICellRendererParams;

    // gets calledo nce before the renderer is used
    agInit(params: ICellRendererParams): void {
        this.params = params;
    }

    // gets called whenever the cell refreshes
    refresh(params: ICellRendererParams): boolean {
        return false;
    }

    public invokeEditFunction(): void {
        this.params.context.componentParent.editActionRendererFunction(this.params.data);
    }

    public invokeDeleteFunction(): void {
        this.params.context.componentParent.deleteActionRendererFunction(this.params.data);
    }

}