import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { ConfigService } from './app/services/config.service';
import { HriuConsole } from './app/utilities/console-utils';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

document.addEventListener('DOMContentLoaded', () => {
  (async () => {
    if (window !== window.parent && !window.opener) {
      return;
    }

    await ConfigService.loadAppConfig();

    platformBrowserDynamic().bootstrapModule(AppModule)
      .catch(err => HriuConsole.error(err));
  })();
});