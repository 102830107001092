import { HttpBackend, HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BrowserCacheLocation, Configuration, LogLevel } from "@azure/msal-browser";
import { ConfigService } from "src/app/services/config.service";

@Injectable({ providedIn: 'root' })
export class environment {
	// App.config parsing
	static get baseUrl(): string { return document.getElementsByTagName('base')[0].href; }
	static set baseUrl(value: string) { ConfigService.appConfig.baseUrl = value; }
	static get production(): string { return ConfigService.appConfig?.production; }
	static get enableConsole(): string { return ConfigService.appConfig?.enableConsole; }
	static get ad(): { tenantId: string, clientId: string, ownerGroupId: string, memberGroupId: string } {
		return ConfigService.appConfig?.ad;
	}
	static get mdh(): { mdhOdataRootUrl: string, apimSubsciptionKey: string } {
		return ConfigService.appConfig?.mdh;
	}
	static get functionApp(): { basePath: string, withCredentials: boolean, adScope: string } {
		return ConfigService.appConfig?.functionApp;
	}
}