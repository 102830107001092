import { DialogRef } from '@angular/cdk/dialog';
import { Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
    selector: 'photo-upload-success-dialog',
    templateUrl: './photo-upload-success-dialog.html',
    styleUrls: ['./photo-upload-success-dialog.scss'],
    standalone: true,
    imports: [MatIconModule]
})

export class PhotoUploadSuccessDialog {
    constructor(
        public dialogRef: DialogRef
    ) { }

    closeDialog(): void {
        this.dialogRef.close();
    }
}