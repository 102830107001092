export abstract class MDHServiceUtils {
    /**
     * 
     * @param districtNumber 
     * @returns PayLibrary Code
     */
    public static convertDistrictToPayLibrary(districtNumber: string, country: string): string {
        switch (districtNumber) {
            case '01':
                return 'D01';
            case '02':
                if (country == 'US'){
                    return 'D56';
                }
                else {
                    return 'D01';
                }
            case '04':
                return 'D01';
            case '05':
                return 'D01';
            case '06':
                return 'D01';
            case '08':
                return 'D01';
            case '09':
                return 'D01';
            case '10':
                return 'D01';
            case '13':
                return 'D01';
            case '15':
                return 'D01';
            case '18':
                return 'D01';
            case '21':
                return 'D01';
            case '22':
                return 'D01';
            case '24':
                return 'D01';
            case '26':
                return 'D01';
            case '28':
                return 'D01';
            case '50':
                return 'D56';
            case '51':
                return 'D56';
            case '52':
                return 'D56';
            case '54':
                return 'D56';
            case '55':
                return 'D56';
            case '56':
                return 'D56';
            case '57':
                return 'D56';
            case '61':
                return 'D56';
            case '62':
                return 'D56';
            case '63':
                return 'D56';
            case '66':
                return 'D56';
            case '91':
                return 'D01';
            case '92':
                return 'D01';
            case '93':
                return 'D56';
            case '94':
                return 'D01';
            default:
                return 'Invalid DistrictNumber';
        }
    }

    public static convertDistrictNumberToDistrictName(districtNumber: string): string {
        switch(districtNumber) {
            case '01':
                return 'Edmonton NAHQ';
            case '02':
                return 'Resources';
            case '04':
                return 'Calgary';
            case '05':
                return 'Edmonton Buildings';
            case '06':
                return 'Regina';
            case '08':
                return 'Winnipeg';
            case '09':
                return 'Toronto';
            case '10':
                return 'Ottawa';
            case '13':
                return 'Edmonton Industrial Mgmt';
            case '15':
                return 'Edmonton Industrial';
            case '18':
                return 'Montreal';
            case '21':
                return 'Edmonton Energy';
            case '22':
                return 'BC Region';
            case '24':
                return 'Atlantic Canada';
            case '26':
                return 'Edmonton Melloy';
            case '28':
                return 'Saskatoon';
            case '50':
                return 'Denver';
            case '51':
                return "Minneapolis";
            case '52':
                return 'California Buildings';
            case '54':
                return 'Civil Infrastructure';
            case '55':
                return 'TIG';
            case '56':
                return 'US Head Office';
            case '57':
                return 'Seattle';
            case '61':
                return 'Orlando';
            case '62':
                return 'Bakersfield';
            case '63':
                return 'US PICCo';
            case '66':
                return 'Hawaii';
            case '91':
                return 'Australia';
            case '92':
                return 'Solar Canada';
            case '93':
                return 'Solar USA';
            case '94':
                return 'Solar Australia';
            default:
                return 'Invalid DistrictNumber';
        }
     
    }
}