import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { MatTableModule } from '@angular/material/table';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field'
import { MatSelectModule } from '@angular/material/select';
import { MatOptionModule, MatRippleModule } from '@angular/material/core';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './views/home/home.component';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { IPublicClientApplication, PublicClientApplication, InteractionType } from '@azure/msal-browser';
import {
    MsalBroadcastService, MsalInterceptorConfiguration, MsalModule, MsalService,
    MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalGuardConfiguration, MsalRedirectComponent, ProtectedResourceScopes
} from '@azure/msal-angular';

import { BaseGuard } from './guards/base.guard';
import { GroupGuard } from './guards/group.guard';
import { UnauthorizedComponent } from './views/unauthorized/unauthorized.component';
import { SharePointService } from './services/sharepoint.service';
import { ImageCropperModule } from 'ngx-image-cropper';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSnackBarModule } from '@angular/material/snack-bar';


import { AgGridModule } from 'ag-grid-angular';
import { actionButtonCellRender } from './components/action-button/action-button-cell-renderer';
import { checkBoxCellRender } from './components/check-box-cell-renderer/check-box-cell-renderer';
import { PhotoUploadingDialog } from './components/dialogs/photo-uploading/photo-uploading-dialog';
import { UtilitiesService } from './services/utilities.service';
import { BASE_PATH } from 'variables';
import { AuthInterceptor } from './utilities/auth.interceptor';
import { ConfigService } from './services/config.service';
import { PhotoUploadSuccessDialog } from './components/dialogs/photo-upload-success/photo-upload-success-dialog';

/**
 * Here we pass the configuration parameters to create an MSAL instance.
 * For more info, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/configuration.md
 */
export function MSALInstanceFactory(): IPublicClientApplication {
    return new PublicClientApplication(ConfigService.msalConfig);
}

/**
 * MSAL Angular will automatically retrieve tokens for resources
 * added to protectedResourceMap. For more info, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/initialization.md#get-tokens-for-web-api-calls
 */
export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
    const protectedResourceMap = new Map<string, Array<string | ProtectedResourceScopes> | null>();

    // protectedResourceMap.set(protectedResources.apiTodoList.endpoint, protectedResources.apiTodoList.scopes);

    return {
        interactionType: InteractionType.Popup,
        protectedResourceMap,
    };
}

/**
 * Set your default interaction type for MSALGuard here. If you have any
 * additional scopes you want the user to consent upon login, add them here as well.
 */
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
    return {
        interactionType: InteractionType.Redirect,
        authRequest: ConfigService.loginRequest
    };
}

// TODO: Split the material modules into a separate file material.module.ts
@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        UnauthorizedComponent,
        actionButtonCellRender,
        checkBoxCellRender
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatToolbarModule,
        MatListModule,
        MatTableModule,
        MatCardModule,
        MatFormFieldModule,
        MatCheckboxModule,
        MatIconModule,
        MatSelectModule,
        MatInputModule,
        MatOptionModule,
        MatDialogModule,
        MatRippleModule,
        MatAutocompleteModule,
        MatSnackBarModule,
        MatProgressSpinnerModule,
        HttpClientModule,
        FormsModule,
        MsalModule,
        MsalModule,
        ImageCropperModule,
        AgGridModule,
        PhotoUploadingDialog,
        PhotoUploadSuccessDialog
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory
        },
        {
            provide: MSAL_GUARD_CONFIG,
            useFactory: MSALGuardConfigFactory
        },
        {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: MSALInterceptorConfigFactory
        },
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: {
                appearance: 'outline'
            }
        },
        {
            provide: MAT_DIALOG_DEFAULT_OPTIONS, 
            useValue: { 
                hasBackdrop: true 
            } 
        },
        {
            provide: BASE_PATH,
            useValue: '/'
        },
        MsalService,
        BaseGuard,
        GroupGuard,
        MsalBroadcastService,
        SharePointService,
        UtilitiesService
    ],
    bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
