import { DialogRef } from '@angular/cdk/dialog';
import { Component } from '@angular/core';
import {MatProgressBarModule} from '@angular/material/progress-bar';




@Component({
    selector: 'photo-uploading-dialog',
    templateUrl: './photo-uploading-dialog.html',
    styleUrls: ['./photo-uploading-dialog.scss'],
    standalone: true,
    imports: [MatProgressBarModule]
})

export class PhotoUploadingDialog {
    constructor(
        public dialogRef: DialogRef
    ) { }

    closeDialog(): void {
        this.dialogRef.close();
    }
}