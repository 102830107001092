import { Component, OnInit, ViewChild, ViewChildren, ViewContainerRef } from '@angular/core';
import { debounceTime, filter } from 'rxjs/operators';

import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { EventMessage, EventType, AuthenticationResult, InteractionStatus } from '@azure/msal-browser';
import { createClaimsTable } from '../../utilities/claim-utils';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

// source: https://github.com/Mawi137/ngx-image-cropper
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';
import { ImageTransform } from 'ngx-image-cropper';
import { SharePointService } from 'src/app/services/sharepoint.service';
import { HttpClient } from '@angular/common/http';
import { employeeDto } from 'src/app/models/employee.dto';
import { MDHService } from 'src/app/services/mdh.service';
import { HriuConsole } from 'src/app/utilities/console-utils';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

import { AgGridAngular } from 'ag-grid-angular';
import { photoAgGridDto, photoGetDto } from 'src/app/models/photo.dto';
import { ColDef } from 'ag-grid-community';
import { actionButtonCellRender } from 'src/app/components/action-button/action-button-cell-renderer';
import { GridOptions } from 'ag-grid-community';
import { ColumnApi } from 'ag-grid-community';
import { GridApi } from 'ag-grid-community';
import { GridReadyEvent } from 'ag-grid-community';
import { checkBoxCellRender } from 'src/app/components/check-box-cell-renderer/check-box-cell-renderer';
import { MatSnackBar } from '@angular/material/snack-bar';

// test only
import { lastValueFrom } from "rxjs";
import { UploadPhotoRequest } from 'src/app/models/uploadPhotoRequest';
import { MatDialog } from '@angular/material/dialog';
import { PhotoUploadingDialog } from 'src/app/components/dialogs/photo-uploading/photo-uploading-dialog';
import { PhotoUploadSuccessDialog } from 'src/app/components/dialogs/photo-upload-success/photo-upload-success-dialog';
import { MDHServiceUtils } from 'src/app/utilities/mdh-service-utils';
import { Dialog } from '@angular/cdk/dialog';


@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit {
    public form: FormGroup;
    
    loginDisplay = false;
    displayedColumns: string[] = ['claim', 'value', 'description'];
    dataSource: any = [];

    // services
    sharePointService: SharePointService | undefined;
    mdhService: MDHService | undefined;
    

    // employee lookup
    filteredEmployees: Array<employeeDto> = [];
    currentEmployee: employeeDto = {uen: '',  firstName: '', lastName: '', workEmail: '', district: '', employeeNumber: null, fullName: ''};
    
    // photo history
    photoHistoriesToLoad: Array<photoAgGridDto> = [];

    
    // image cropper
    imageChangedEvent: any = '';
    croppedImage: any = '';
    resizedImage200: any = '';
    resizedImageEC: any = '';
    loadedImage: any = '';
    emtpyImage: any = '';
    imageFile: any = '';
    isImageLoaded: boolean = false;
    transform: ImageTransform = {};
    rotation = 0; 
    scale = 1;

    originalImageResolution: string = '';

    // ag grid
    gridOptions: GridOptions<any>;
    gridApi!: GridApi<any>;
    columnApi!: ColumnApi;
    
    columnDefs: ColDef[] = [
        {field: 'name'},
        {field: 'lastModifiedDate'},
        {field: 'corporateImage', cellRenderer: checkBoxCellRender},
        {field: 'Action', cellRenderer: actionButtonCellRender}
    ];

    rowData: Array<photoAgGridDto> = this.photoHistoriesToLoad;


    constructor(
        private authService: MsalService, 
        private msalBroadcastService: MsalBroadcastService, 
        private httpClient: HttpClient, 
        private _snackBar: MatSnackBar,
        public dialog: Dialog
        ) {
        this.form = new FormGroup({
            uen: new FormControl('', [Validators.required]),
            firstName: new FormControl('', [Validators.required]),
            lastName: new FormControl('', [Validators.required]),
            workEmail: new FormControl('', [Validators.required]),
            districtNumber: new FormControl('', []),
            district: new FormControl('', []),
            employeeNumber: new FormControl('', []),
            personalConsent: new FormControl('', [])
        },{
            validators: [
                this.autocompletionValidator
            ]
        });

        this.gridOptions = <GridOptions>{
            context: {
                componentParent: this
            }
        }
    }
    
    ngOnInit(): void {
        this.mdhService = new MDHService(this.authService, this.httpClient);

        this.msalBroadcastService.msalSubject$
            .pipe(
                filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
            )
            .subscribe((result: EventMessage) => {
                const payload = result.payload as AuthenticationResult;
                
                this.authService.instance.setActiveAccount(payload.account);

                // if user just signed in, create new sharePointService
                this.sharePointService = new SharePointService(this.httpClient, payload.accessToken);
                localStorage.setItem('msal.accessToken.key', payload.accessToken);
            });
        
        this.msalBroadcastService.inProgress$
            .pipe(
                filter((status: InteractionStatus) => status === InteractionStatus.None)
            )
            .subscribe(() => {

                this.setLoginDisplay();
                this.getClaims(this.authService.instance.getActiveAccount()?.idTokenClaims);
                
                // if sharePointService hasn't been created (when user already signed in then refreshed the page)
                // get the accessToken from localStorage
                if (!this.sharePointService) {
                    this.sharePointService = new SharePointService(this.httpClient, localStorage.getItem('msal.accessToken.key')!);
                }  
            })
        
        // console.log(localStorage.getItem('msal.accessToken.key'));
        // if (localStorage.getItem('msal.accessToken.key')) {
        //     this.sharePointService = new SharePointService(this.httpClient, localStorage.getItem('msal.accessToken.key')!);
        // }

        this.form.valueChanges.pipe(debounceTime(300)).subscribe((data) => 
        {				
            // detect form change automatically to take advantage of angular debounce time
            // this will only read user input once after user finishes typing instead of reading it multiple times letter by letter
            if (data.workEmail){
                this.searchEmployeeUsingWorkEmail(data.workEmail);
            }
            if (data.firstName){
                this.searchEmployeeUsingFirstName(data.firstName);
            }
            if (data.lastName){
                this.searchEmployeeUsingLastName(data.lastName);
            }
            
        });
    }

    setLoginDisplay() {
        this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
    }

    getClaims(claims: any) {
        if (claims) {
            const claimsTable = createClaimsTable(claims);
            this.dataSource = [...claimsTable];
        }
    }

    openSnackBar(message: string, action: string, panelClass:any) {
        this._snackBar.open(message, action, panelClass);
    }

    // image cropper basic funcs

    fileChangeEvent(event: any): void {
        console.warn(event);
        this.imageChangedEvent = event;
        this.originalImageResolution = event.target.files[0].size;
    }

    // helper function for fileChangeEvent, read the image from fileInput 
    // fileLoaded = (event) => {
        
    // }
    imageCropped(event: ImageCroppedEvent) {
        this.croppedImage = event.base64;
    }
    imageLoaded(image: LoadedImage) {
        // show cropper
        this.loadedImage = image;
        this.imageFile = '';
        this.isImageLoaded = true;
    }
    cropperReady() {
        // cropper ready
    }
    loadImageFailed() {
        // show message
        this.openSnackBar("Failed to load photo, please try another one", "Close", {
            panelClass: ['error-snackbar']
        });
    }

    // image cropper manipulations

    resetImage() {
        this.rotation = 0;
        this.transform = {};
    }

    imageRotate() {
        this.rotation -= 90;
        this.transform = {
            ...this.transform,
            rotate: this.rotation
          };
    }

    imageZoomOut() {
        this.scale -= 0.2;
        this.transform = {
            ...this.transform,
            scale: this.scale
        };
    }

    imageLoadedBlob(imageBlob: Blob) {
        this.loadedImage = '';
        this.imageFile = imageBlob;
        this.isImageLoaded = true;
    }
    
    resizeImage(width: number, height: number): Promise<string> {
        return new Promise((resolve, reject) => {
            // resize image based on the width and height that were passed in
            const canvas = document.createElement('canvas');
            canvas.width = width;
            canvas.height = height;
            const ctx = canvas.getContext('2d');
            const img = new Image();
            
            if (this.croppedImage){
                img.src = this.croppedImage;               
                img.onload = function() {
                    let startingX: number = (240 - width) / 2;
                    let startingY: number = (240 - height) / 2;

                    ctx?.drawImage(img, startingX, startingY, width, height, 0, 0, width, height);
                    const resizedImageBase64 = canvas.toDataURL('image/jpeg');
                    
                    resolve(resizedImageBase64);
                }
            }
            else {
                reject("Invalid Image");
            }
        });
    }

    // upload cropped image to sharepoint, need to upload 3 copies of different sizes
    async uploadImage() {
        // evaluate form validity first
        this.form.updateValueAndValidity();

        // exit early if the form is not valid
        if (this.form.invalid)
        {
            this.openSnackBar("Oops! Please choose an employee first", "Close", {
                panelClass: ['error-snackbar']
            });
            return;
        }

        
        if (this.sharePointService){
            try {
                // resize the image from 240 X 240 to 200 X 200
                await this.resizeImage(200,200)
                .then((resolve) => {
                    this.resizedImage200 = resolve;
                },
                (reject) => {
                    this.openSnackBar("Invalid Photo", "Close", {
                        panelClass: ['error-snackbar']
                    });
                    // exit function
                    throw("Invalid Photo");
                });

                // resize the image from 240 X 240 to 180 to 240
                await this.resizeImage(180,240)
                .then((resolve) => {
                    this.resizedImageEC = resolve;
                },
                (reject) => {
                    this.openSnackBar("Invalid Photo", "Close", {
                        panelClass: ['error-snackbar']
                    });
                    // exit function
                    throw("Invalid Photo");
                });
            }
            catch{
                // handle the error gracefully without showing error msg in console, exit function
                return;
            }

            this.openDialog(PhotoUploadingDialog);

            // upload all photos to sharepoint
            try {
                // get current month name + year e.g. April 2023 
                let currentDate = `${new Date().toLocaleDateString('default', {month: 'short'})} ${new Date().getFullYear()}`;

                // upload the photo with the original resolution
                let uploadPhotoRequest: UploadPhotoRequest = 
                {
                    image: [{
                        imageString: this.resizedImageEC,
                        resolution: 180
                    },
                    {
                        imageString: this.resizedImage200,
                        resolution: 200
                    },
                    {
                        imageString: this.loadedImage.original.base64,
                        resolution: parseInt(this.originalImageResolution)
                    }],
                    description: currentDate,
                    firstName: this.currentEmployee.firstName,
                    lastName: this.currentEmployee.lastName,
                    // employeeNumber was initialized as null so added optional chaining
                    // employeeNumber can be string | null but optional chaining can return undefined so added nullish coalescing
                    employeeNumber: this.currentEmployee.employeeNumber?.[0]?.PayrollNumber ?? null, 
                    uen: this.currentEmployee.uen,
                    uploaderOfficeId: '',
                    uploaderOffice: ''
                };
                
                await lastValueFrom(this.sharePointService.uploadPhoto(uploadPhotoRequest))
                .then((resolve: any) => {
                },(reject: any) => {
                    throw(reject);
                });
  
            }
            catch(e) {
                console.error(e);
                this.dialog.closeAll();
                this.openSnackBar("Oops! Something Went Wrong. Please Contact Your System Administrator", "Close", {
                    panelClass: ['error-snackbar']
                });

                return e;
            }
            
            this.form.reset();
            this.dialog.closeAll();
            
            this.openSuccessDialog();

            setTimeout(() => {
                this.dialog.closeAll();
            }, 6000)
        }
        else {
            this.openSnackBar("Oops! Something Went Wrong. Please Contact Your System Administrator", "Close", {
                panelClass: ['error-snackbar']
            });
            // exit function
            throw("Sharepoint service not initialized");
        }
        
    }

    // file manipulations

    // this function 'click' the HTML element fileInput, this element is bind to the native input tag to invoke a file explorer dialog 
    fileUpload(fileInput: HTMLElement){
        fileInput.click();
    }
    
    // when file input is clicked, clear the cache on that button
    // if this is skipped, users will not be able to select the same file back to back 
    fileUploadOnClick(e: any){
        e.target.value = "";
    }

    fileClear() {
        // reset all image and file vars
        this.loadedImage = '';
        this.imageFile = '';
        this.imageChangedEvent = '';
        this.croppedImage = '';
        this.isImageLoaded = false;
    }

    // async fetchHistoricPhotos(): Promise<photoGetDto[]>{
    //     let photos: Array<photoGetDto> = [];
    //     if (this.sharePointService)
    //     {
    //         let employeeNumber = "D0116027";
    //         photos = await this.sharePointService?.getCurrentPhotos(employeeNumber)
    //             .then(resolve: any => {
    //                 console.log(resolve);
    //                 return resolve;
    //             },
    //             reject: any => {
    //                 return reject;
    //             });
    //         console.log(photos);
    //     }

    //     return photos
    // }

    // ag grid functions
    onGridReady(params: GridReadyEvent<any>) {    
        this.columnApi = params.columnApi;
        this.columnApi.autoSizeAllColumns(false);

    }

    editActionRendererFunction(data: photoAgGridDto) {
        this.imageLoadedBlob(data.photoBlob);
        console.log(data);
    }

    deleteActionRendererFunction(data: photoAgGridDto) {
        
        // this.sharepointService?.deletePhoto(data.id);
    }

 
    // TODO: add logic to get pay library from district, get employee number
    get formValue(){
        return this.form.value;
    }

    // search for employee from MDH
    async searchEmployeeUsingFirstName(firstNameInput: string){
        this.filteredEmployees = [];
        await this.mdhService?.getEmployeeFirstNameStartWith(firstNameInput)
            .then(
                (resolve) => {
                    this.filteredEmployees = resolve; 
                    return resolve
                },
                (reject) => {
                    console.log(reject);
                    return reject;
                }
            );
    }

    async searchEmployeeUsingLastName(lastNameInput: string){
        this.filteredEmployees = [];
        await this.mdhService?.getEmployeeLastNameStartWith(lastNameInput)
            .then(
                (resolve) => {
                    this.filteredEmployees = resolve; 

                    return resolve
                },
                (reject) => {
                    return reject;
                }
            );
    }

    async searchEmployeeUsingWorkEmail(workEmailInut: string){
        // clean the array everytime at the beginning of the searching 
        this.filteredEmployees = [];
        await this.mdhService?.getEmployeeWorkEmailStartsWith(workEmailInut)
            .then(
                (resolve) => {
                    this.filteredEmployees = resolve;
                    return resolve;
                },
                (reject) => {
                    return reject;
                }
            );
    }

    // autocomplete employee from MDH
    employeeSelected(e: MatAutocompleteSelectedEvent) {
        if (e.option.value) {
            // rendering ag form needs to be put inside setTimeOut
			// it stops the form from being rendered by other functions and avoid conflicts and errors 
            setTimeout(async () => {
                await this.form.controls['uen'].patchValue(e.option.value.uen);
                await this.form.controls['firstName'].patchValue(e.option.value.firstName);
                await this.form.controls['lastName'].patchValue(e.option.value.lastName);
                await this.form.controls['workEmail'].patchValue(e.option.value.workEmail);
                await this.form.controls['districtNumber'].patchValue(e.option.value.district); // e.option.value.district is district number, not the district in text form
                await this.form.controls['district'].patchValue(MDHServiceUtils.convertDistrictNumberToDistrictName(e.option.value.district)); // use e.option.value.district to lookup the district name
                // TODO: set personal consent from MDH 
                // await this.form.controls['personalConsent'].patchValue(e.option.value.personalConsent);

                // retrieve full employee detail from MDH for the currently selected employee 
                await this.mdhService?.getEmployeeDetail(e.option.value.uen)
                    .then((resolve) => {
                        this.currentEmployee = resolve;
                    },
                    (reject) => {
                        console.error(reject)
                    });

                this.fileClear();
                // this.currentEmployee
            })
        }
    }

    clearEmployeeSelected() {
        setTimeout(async () => {
            await this.form.controls['uen'].patchValue("");
            await this.form.controls['firstName'].patchValue("");
            await this.form.controls['lastName'].patchValue("");
            await this.form.controls['workEmail'].patchValue("");
            await this.form.controls['districtNumber'].patchValue(""); // e.option.value.district is district number, not the district in text form
            await this.form.controls['district'].patchValue(""); // use e.option.value.district to lookup the district name
            this.filteredEmployees = [];
        });
        
    }


    // if the employee is not selected through autocompletion
	public autocompletionValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null =>{
		let errors: ValidationErrors | null = {};
		
		if (!this.currentEmployee?.workEmail){
			return { autocompleted: false };
		}

		return null;
	};

    openDialog(dialogComponent: any): void {
        const dialogRef = this.dialog.open(dialogComponent);
    }
    

    // test the connection to the function app
    public async testConnection(){
        await lastValueFrom(this.httpClient.get(`http://localhost:7255/api/ConnectionTest`)).then((resolve)=> {console.log(resolve)}, (reject) => {console.log(reject)});
    }


    // temporary
    // TODO: remove after finished testing
    openUploadingDialog(): void {
        this.openDialog(PhotoUploadingDialog);
    }

    openSuccessDialog(): void {
        this.openDialog(PhotoUploadSuccessDialog);
    }
}


