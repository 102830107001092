<div class="loading-widget">
    <div class="loading-widget-content">
        <div class="loading-widget-text">
            <div class="custom-spinner-title" mat-dialog-title>SIT TIGHT!</div>            
            <div>Great photos take a moment to upload!</div>
        </div>
        <div class="divider-line"></div>
        <div class="loading-widget-image-container">
            <img class="loading-widget-image" src="/assets/images/camera.jpg"/>
        </div>
    </div>
    <div class="custom-progress-bar">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
</div>
