<mat-toolbar>
    <img class="pcl-logo" src="../assets/images/pcl-logo.png" alt="PCL Logo">
    <h2 class="title" [routerLink]="['/']">{{ title }}</h2>
    <div class="toolbar-spacer"></div>
    <button class="header-button" *ngIf="!loginDisplay" (click)="login()">
        <mat-icon>login</mat-icon> Login
    </button>
    <button class="header-button" *ngIf="loginDisplay" (click)="logout()">
        <mat-icon>logout</mat-icon> Logout
    </button>
</mat-toolbar>

<div class="container">
    <!--This is to avoid reload during acquireTokenSilent() because of hidden iframe -->
    <router-outlet *ngIf="!isIframe">
        <button class="big-login-btn" *ngIf="!loginDisplay" (click)="login()">
            <mat-icon>login</mat-icon>
            <br/>
            Login
        </button>    
    </router-outlet>
</div>