import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MsalService } from '@azure/msal-angular';

@Component({
	selector: 'app-session-timeout',
	templateUrl: './session-timeout.component.html'
})
export class SessionTimeoutComponent implements OnInit, OnDestroy {
	constructor(
		private dialogRef: MatDialogRef<SessionTimeoutComponent>,
		private msalService: MsalService) {
	}

	async signIn(): Promise<void> {
    this.msalService.loginPopup().toPromise().then((result) => {
      if (result && !(result.idToken === null || result.idToken === undefined || result.idToken === '')) {
        this.dialogRef.close();
			}
		});
	}

	ngOnInit(): void {
	}

	ngOnDestroy(): void {
	}
}
