import { Injectable } from '@angular/core';

import { AuthenticationResult, InteractionRequiredAuthError } from '@azure/msal-browser';
import { MsalService } from '@azure/msal-angular';
import { Client, PageCollection } from '@microsoft/microsoft-graph-client';

import { graphTokenDto } from '../models/graphToken.dto';
import { ConfigService } from './config.service';

@Injectable({
    providedIn: 'root'
})
export class GraphService {

    constructor(private authService: MsalService) { }

    protected getGraphClient(accessToken: string) {
        // Initialize Graph client
        const graphClient = Client.init({
            // Use the provided access token to authenticate requests
            authProvider: (done) => {
                done(null, accessToken);
            },
        });

        return graphClient;
    };

    protected async getTokens(scopes: string[]): Promise<graphTokenDto> {
        let authResponse: AuthenticationResult | null = null;

        try {
            authResponse = await this.authService.instance.acquireTokenSilent({
                account: this.authService.instance.getActiveAccount()!,
                scopes: scopes,
            });

        } catch (error) {
            if (error instanceof InteractionRequiredAuthError) {
                // TODO: get default interaction type from auth config

                authResponse = await this.authService.instance.acquireTokenPopup({
                    scopes: ConfigService.protectedResources.apiGraph.scopes,
                });
            }

            console.log(error);
        }
    
        let tokens: graphTokenDto = {
            idToken: '',
            accessToken: ''
        };
        if (authResponse) {
            tokens = {
                accessToken : authResponse.accessToken,
                idToken : authResponse.idToken
            }
        }
        
        return tokens
        
    }

    async getFilteredGroups(filterGroups: string[] = []): Promise<string[]> {
        let groups: string[] = [];

        try {
            const graphTokens = await this.getTokens(ConfigService.protectedResources.apiGraph.scopes);

            // Get a graph client instance for the given access token
            const graphClient = this.getGraphClient(graphTokens.accessToken);

            // Makes request to fetch groups list, which is expected to have multiple pages of data.
            let response: PageCollection = await graphClient.api(ConfigService.protectedResources.apiGraph.endpoint)
                .post({
                    groupIds: filterGroups
                });

            groups = response.value;
            return groups;
        } catch (error) {
            console.log(error);
        }

        return groups;
    }
}