import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MsalBroadcastService } from '@azure/msal-angular';
import { EventType, EventMessage } from '@azure/msal-browser';
import { filter } from 'rxjs';

@Component({
    selector: 'app-unauthorized',
    templateUrl: './unauthorized.component.html',
    styleUrls: ['./unauthorized.component.scss']
})
export class UnauthorizedComponent implements OnInit {


    constructor(
        private msalBroadcastService: MsalBroadcastService,
        private router: Router
    ) {}

    ngOnInit(): void {
        
        this.msalBroadcastService.msalSubject$
            .pipe(
                filter((msg: EventMessage) => msg.eventType == EventType.LOGIN_FAILURE)
            )
            .subscribe(() => {
                this.router.navigate(['/unauthorized']);
            })
    }
}