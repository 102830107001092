import { Injectable, OnInit } from '@angular/core';
import { MsalGuardAuthRequest } from '@azure/msal-angular';
import { BrowserCacheLocation, Configuration, LogLevel } from '@azure/msal-browser';
import { environment } from 'src/environments/environment';
import { HriuConsole } from '../utilities/console-utils';

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

@Injectable()
export class ConfigService {
  private static _appConfig: any = {};
  public baseUrl?: string;
  public static get appConfig(): any { return ConfigService._appConfig; }

  static async loadAppConfig(): Promise<any> {
    const response = await fetch(environment.baseUrl + 'assets/app.json');
    const config = await response.json();

    ConfigService._appConfig = config;
    
    HriuConsole.info('Config Loaded: ' + environment.functionApp.basePath);
	HriuConsole.info('Config Loaded: ' + environment.functionApp.adScope);
    return config;
  }

  
	// Auth Configuration for MSAL
	static get msalConfig(): Configuration {
		return {
			auth: {
				clientId: environment.ad.clientId, // This is the ONLY mandatory field that you need to supply.
				authority: 'https://login.microsoftonline.com/' + environment.ad.tenantId, // Defaults to "https://login.microsoftonline.com/common"
				redirectUri: '/auth', // Points to window.location.origin by default. You must register this URI on Azure portal/App Registration.
				postLogoutRedirectUri: '/', // Points to window.location.origin by default.
			},
			cache: {
				cacheLocation: BrowserCacheLocation.LocalStorage, // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
				storeAuthStateInCookie: isIE, // Set this to "true" if you are having issues on IE11 or Edge. Remove this line to use Angular Universal
			},
			system: {
				/**
				 * Below you can configure MSAL.js logs. For more information, visit:
				 * https://docs.microsoft.com/azure/active-directory/develop/msal-logging-js
				 */
				loggerOptions: {
					loggerCallback(logLevel: LogLevel, message: string) {
						console.log(message);
					},
					logLevel: LogLevel.Verbose,
					piiLoggingEnabled: false
				}
			}
		}
	}

	/**
	 * Add here the endpoints and scopes when obtaining an access token for protected web APIs. For more information, see:
	 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
	 */
	static get protectedResources() {
		return { 
			apiGraph: {
				endpoint: "https://graph.microsoft.com/v1.0/me/checkMemberGroups",
				scopes: []
				//scopes: ["User.Read"]
			}
		}
	}

	/**
	 * Scopes you add here will be prompted for user consent during sign-in.
	 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
	 * For more information about OIDC scopes, visit:
	 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
	 */
	static get loginRequest(): MsalGuardAuthRequest {
		return { 
			scopes: [...ConfigService.protectedResources.apiGraph.scopes, environment.functionApp.adScope] 
		
		}
	};
}
