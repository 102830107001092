import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
    selector: 'checkbox-cell-renderer',
    template: 
        `
        <mat-checkbox color='primary'></mat-checkbox>
        `
})

export class checkBoxCellRender implements ICellRendererAngularComp {
    private params!: ICellRendererParams;

    // gets calledo nce before the renderer is used
    agInit(params: ICellRendererParams): void {
        this.params = params;
    }

    // gets called whenever the cell refreshes
    refresh(params: ICellRendererParams): boolean {
        return false;
    }
    

}