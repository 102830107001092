<h2 mat-dialog-title>Session Expired</h2>
<div mat-dialog-content>
	Please sign in again to continue
</div>
<div mat-dialog-actions fxLayout="row" fxLayoutAlign="end">
	<button mat-raised-button
			class="btn-black"
	        onclick="this.blur()"
	        (click)="signIn()">
		<span>Sign In</span>
	</button>
</div>
